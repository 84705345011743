<template>
  <div>
    <a-card title="配置详情" style="width: 80%; margin: 2% auto">
      <template slot="extra">
        <a-select
          v-model="languageSelect"
          allowClear
          placeholder="请选择翻译语言"
          showSearch
          optionFilterProp="children"
          @change="handleLanguage"
          :style="{ width: '8vw' }"
        >
          <a-select-option v-for="(language, key) in languageOptions" :key="key" :value="language.value">{{
            language.label
          }}</a-select-option>
        </a-select>
      </template>
      <a-descriptions bordered>
        <a-descriptions-item label="配置ID">{{ form.id }} </a-descriptions-item>
        <a-descriptions-item label="配置标识符"> {{ form.identifier }} </a-descriptions-item>
        <a-descriptions-item label="配置描述" v-if="form.description">{{
          form.description.defaultMessage ? form.description.defaultMessage : '暂无'
        }}</a-descriptions-item>
        <a-descriptions-item label="配置项定义唯一标识"> {{ form.encode }} </a-descriptions-item>
        <a-descriptions-item label="属性类型">
          <span>{{ propertyType(form) }}</span>
        </a-descriptions-item>
        <a-descriptions-item label="编辑方式">
          <span>{{ propertyEditMode(form) }}</span>
        </a-descriptions-item>
        <a-descriptions-item label="产品配置项类型">
          <span>{{ itemTypeName(form) }}</span>
        </a-descriptions-item>
        <a-descriptions-item label="默认值">
          <span>{{ form.type.defaultValue || '无' }}</span>
        </a-descriptions-item>
        <template v-if="form.type.editMode == $constRepository.productFormItem.editMode.input">
          <a-descriptions-item label="范围">
            <span v-if="form.type.type !== $constRepository.product.DATA_PRIMITIVE_TYPES.BOOL">{{
              `${form.type.range.min}-${form.type.range.max}`
            }}</span>
            <span v-else>无</span>
          </a-descriptions-item>
        </template>
        <template v-if="form.type.editMode == $constRepository.productFormItem.editMode.select">
          <a-descriptions-item label="选项">
            <p v-for="(option, index) in form.type.options" :key="index">
              {{ `${option.value}:${option.description.defaultMessage}` }}
            </p>
          </a-descriptions-item>
        </template>
      </a-descriptions>
    </a-card>
    <a-card title="配置数据" style="width: 80%; margin: 0 auto; margin-bottom: 2%">
      <vue-json-editor v-model="form" :showBtns="false" :mode="'code'" lang="zh" style="height: 800px" />
      <a-button type="primary" class="creator-btn" @click="$router.back()">返回</a-button>
    </a-card>
  </div>
</template>

<script>
import vueJsonEditor from 'vue-json-editor';

export default {
  name: 'PropertyDetail',
  components: { vueJsonEditor },
  mounted() {
    if (this.$route.params.id) {
      Promise.all([this.getProductConfiguration(this.$route.params.id), this.getMetaData()]);
    }
  },
  data() {
    return {
      languageSelect: this.$constRepository.language.FILTER_OPTION_ZH_CN.value,
      languageOptions: this.$constRepository.language.FILTER_OPTIONS,
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      form: {
        type: {},
      },
      metaData: {},
    };
  },
  methods: {
    async getMetaData() {
      const response = await this.$apiManager.productMetaData.getMetaData();
      this.metaData = response.data;
    },
    async handleLanguage() {
      await this.getProductConfiguration(this.$route.params.id);
    },
    async getProductConfiguration(ids) {
      const headers = {
        'Accept-Language': this.languageSelect,
      };
      const response = await this.$apiManager.productConfiguration.getProductConfigurationById(ids, headers);
      this.form = response.data[0];
    },
  },
  computed: {
    propertyType() {
      return (property) => {
        const res = this.metaData.dataPrimitiveTypes?.find((type) => type.value === property.type.type);
        if (res) {
          return res.name;
        }
        return '';
      };
    },
    propertyEditMode() {
      return (property) => this.$constRepository.productFormItem.editModeText[property.type.editMode] || '';
    },
    itemTypeName() {
      return (property) => {
        const res = this.metaData.productConfItemTypes?.find((type) => type.value === property.itemType);
        if (res) {
          return res.name;
        }
        return '';
      };
    },
  },
};
</script>

<style lang="less" scoped>
/deep/.jsoneditor-poweredBy {
  display: none;
}
// /deep/.jsoneditor-outer {
//   height: 25vw;
// }
/deep/ .jsoneditor-vue {
  height: 100%;
}
.creator-btn {
  margin-top: 2%;
}
.default-message-edit {
  height: 8vw;
  /deep/.jsoneditor-outer {
    height: 8vw;
  }
}
.loadDevice_title {
  margin-top: 4%;
  margin-bottom: 20px;
  color: rgba(0, 0, 0, 0.85);
  font-weight: bold;
  font-size: 16px;
  line-height: 1.5;
}
.params_descriptions {
  /deep/ .ant-descriptions-item-content {
    width: 300px;
    word-break: break-all;
  }
}
</style>
